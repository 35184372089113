import React from "react"

const BlockQuote = ({ data }) => {
  return (
    <div className="uk-section uk-section-default uk-padding-remove">
      <div className="uk-container uk-container-small uk-margin uk-margin-small-bottom">
        <blockquote className="otro-blockquote uk-background-muted">
          {data.quoteBody}
          <span>{data.title}</span>
        </blockquote>
      </div>
    </div>
  )
}

export default BlockQuote
