import React from "react"
//import Headings from "./headings"
import ReactLightbox from "./react-lightbox"

const BlockGallery = ({ data }) => {
  //const files = data.files
  //console.log("Data - BlockGallery: ", data)

  const { title, description, files, bg } = data

  return (
    <>
      <ReactLightbox
        images={files}
        location={`${title} Page`}
        title={title}
        description={description}
        bg={`${bg}`} 
        //galleryImages={galleryImages}
        //remainingImages={remainingImages}
      />
    </>
  )
}

export default BlockGallery
