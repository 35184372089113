import React from "react"
import Headings from "./headings"

const BlockVideo = ({ data }) => {
  //console.log("Block Video - Data: ", data)

  const { headings, src, title, width, height, bg = "uk-background-default" } = data
  //console.log("headings: ", headings)
  //console.log("src: ", src)
  //console.log("title: ", title)
  //console.log("width: ", width)
  //console.log("height: ", height)

  return (
    <div 
      className={`uk-section ${bg} uk-padding-remove`} 
      //style={{ marginBottom: "20px"}}
    >
      <div className="uk-container uk-margin-small-bottom">
        <Headings
          title={headings.title}
          description={headings.description}
          bg={bg}
        />
        
        <div className="uk-container uk-margin-small-bottom">
          <iframe
          src={src}
          width={width}
          height={height}
          title={title}
          style={{ border: "none", overflow: "hidden", marginBottom: "28px" }}
          //scrolling="no"
          //frameBorder="0"
          allowFullScreen={true}
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        ></iframe>
        </div>
      </div>
    </div>
  )
}

export default BlockVideo
