import React from "react"
import CtaSlider from "./cta-slider"

const BlockCta = ({ data }) => {
    //console.log("Block CTA: ", data)

    const { title, description, call_to_action_sections, bg = "uk-section-muted" } = data
    
    return (
        <>
            <CtaSlider 
                title={title} 
                description={description} 
                callToActionSections={call_to_action_sections} 
                bg={bg}
            />
        </>        
    )
}

export default BlockCta