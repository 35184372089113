import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BlocksRenderer from "../components/blocks-renderer"
import Headings from "../components/headings"

const AboutPage = () => {
  const { strapiAbout } = useStaticQuery(graphql`
    query {
      strapiAbout {
        title
        blocks {
          ...Blocks
        }
      }
    }
  `)
  const { blocks } = strapiAbout

  /* const seo = {
    metaTitle: title,
    metaDescription: title,
  } */

  return (
    <>
      {/* <Seo seo={seo} /> */}
      <Headings title={strapiAbout.title} />
      <BlocksRenderer blocks={blocks} />
    </>
  )
}

export default AboutPage
