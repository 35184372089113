import React from "react"
import Markdown from "react-markdown"

const BlockRichText = ({ data }) => {
  //console.log("Data: ", data.richTextBody)
  return (
    <div className="uk-section uk-section-default uk-padding-remove">
      <div className="uk-container">
        <Markdown
          className="uk-padding-remove-bottom"
          children={data.richTextBody.data.body}
        />
      </div>
    </div>
  )
}

export default BlockRichText
